import React from 'react'
import Header from './Header.js'
import Footer from './Footer.js'
import PropTypes from 'prop-types'
import { CContainer } from '@coreui/react'

function PlantillaForm({ header, children, footer }) {
  return (
    <>
      <Header
        organismo={header.organismo}
        titulo={header.titulo}
        descripcion={header.descripcion}
        propaganda={header.propaganda}
      />
      <CContainer className="form-estilos">{children}</CContainer>
      <Footer
        organismo={footer?.organismo}
        telefono={footer?.telefono}
        email={footer?.email}
        direccion={footer?.direccion}
        web={footer?.web}
        propaganda={header.propaganda}
      />
    </>
  )
}

PlantillaForm.propTypes = {
  header: PropTypes.shape({
    organismo: PropTypes.string,
    titulo: PropTypes.string,
    descripcion: PropTypes.string,
    propaganda: PropTypes.bool,
  }),
  children: PropTypes.element.isRequired,
  footer: PropTypes.shape({
    organismo: PropTypes.string,
    telefono: PropTypes.string,
    email: PropTypes.string,
    direccion: PropTypes.string,
    web: PropTypes.string,
    propaganda: PropTypes.bool,
  }),
}

export default PlantillaForm
