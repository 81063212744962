import { createStore } from 'redux'

const initialState = {
  sitiosStore: [],
  //Buscador
  ultimaPagBuscador: 1,
  lastSearch: '',
  lastPathname: '',
  //Buscador: filtros
  filtros: null,
  ultimaPagTema: 1,
  ultimoTema: '',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return { ...state }
  }
}

const store = createStore(
  changeState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)
export default store
