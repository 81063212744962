import React from 'react'
import { CContainer, CImage, CNavbar } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { logo } from 'src/assets/brand/logo'
import debate from 'src/assets/images/form/debate.png'
import PropTypes from 'prop-types'

function Header({ titulo, descripcion, organismo, propaganda }) {
  const basePath = String(process.env.PUBLIC_URL).trim()
  return (
    <>
      <CNavbar color="primary">
        <CContainer style={{ width: '70%' }}>
          <a href={basePath + '/'}>
            <CIcon className="sidebar-brand-full" icon={logo} height={43} />
          </a>
        </CContainer>
      </CNavbar>
      {propaganda ? (
        <CContainer
          fluid
          style={{ backgroundColor: '#183F6A', padding: '30px 0', textAlign: 'center' }}
        >
          <CContainer className="form-img">
            <CImage src={debate} className="form-img"></CImage>
          </CContainer>
        </CContainer>
      ) : (
        <CNavbar>
          {Boolean(organismo) && (
            <CContainer style={{ width: '70%' }}>
              <h4 style={{ fontSize: '20px', fontWeight: 600, margin: '5px 0' }}>{organismo}</h4>
            </CContainer>
          )}
        </CNavbar>
      )}
      <CContainer
        fluid
        style={{
          backgroundColor: Boolean(titulo) || Boolean(descripcion) ? '#e2e4e7' : '',
          padding: '30px 0',
        }}
      >
        <CContainer styleName="form-estilos-hd">
          <h1 className={propaganda ? 'form-titulo-propaganda' : 'form-titulo'}>{titulo}</h1>
          <div dangerouslySetInnerHTML={{ __html: descripcion }} />
        </CContainer>
      </CContainer>
    </>
  )
}

Header.propTypes = {
  organismo: PropTypes.string,
  titulo: PropTypes.string,
  descripcion: PropTypes.string,
  propaganda: PropTypes.bool,
}

export default Header
