import { CContainer } from '@coreui/react'
import React from 'react'
import PropTypes from 'prop-types'

function Footer({ organismo, telefono, email, web, propaganda }) {
  let contactos = []
  if (!!telefono) contactos.push(`al teléfono: ${telefono}`)
  if (!!email) contactos.push(`vía mail: ${email}`)
  if (!!web) contactos.push(`visitando: <a href='${web}'>${web}</a>`)

  let textoContacto =
    contactos.length > 1
      ? `${contactos.slice(0, -1).join(', ') + ' o ' + contactos.slice(-1)}`
      : contactos[0]

  return (
    <footer>
      <hr style={{ marginBottom: 0 }} />
      {!!organismo && !propaganda && (
        <CContainer
          style={{
            width: '70%',
            textAlign: 'center',
            fontSize: '1rem',
            lineHeight: '1rem',
            padding: '16px 0',
          }}
        >
          Por cualquier consulta comuníquese con <b>{organismo?.toUpperCase()}</b>
          <br />
          <p
            dangerouslySetInnerHTML={{ __html: textoContacto }}
            style={{ margin: 0, padding: 0 }}
          />
        </CContainer>
      )}
      <div
        style={{
          color: 'white',
          backgroundColor: '#606060',
          fontSize: '0.8rem',
          height: '40px',
          textAlign: 'center',
        }}
        className="center"
      >
        <CContainer style={{ width: '70%' }}>
          Desarrollado: DIRECCIÓN GENERAL DE INFORMÁTICA DE ENTRE RÍOS{' '}
        </CContainer>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  organismo: PropTypes.string,
  telefono: PropTypes.string,
  email: PropTypes.string,
  web: PropTypes.string,
  propaganda: PropTypes.bool,
}

export default Footer
