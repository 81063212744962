export const logo = [
  '46257 8922',
  `
  <title>Entre Ríos</title>
  <defs>
    <style type="text/css">
    <![CDATA[
      .blanco0 {fill:white;fill-rule:nonzero}
    ]]>
    </style>
  </defs>
  <g id="Capa_x0020_1">
    <g id="_1987810378576">
    <path class="blanco0" d="M19163 3060c-101,45 -214,67 -340,67 -116,0 -221,-20 -313,-60 -93,-39 -172,-93 -237,-162 -66,-69 -116,-150 -150,-243 -35,-93 -52,-193 -52,-298 0,-102 18,-199 54,-292 36,-93 86,-175 151,-245 65,-71 143,-127 234,-168 90,-42 192,-62 303,-62 62,0 119,4 170,12 51,9 99,21 144,38 44,17 86,38 124,63 39,24 77,52 114,83l-108 127c-28,-25 -58,-48 -89,-69 -31,-20 -64,-38 -100,-53 -36,-14 -76,-26 -119,-34 -43,-9 -90,-13 -142,-13 -82,0 -158,16 -227,49 -69,32 -128,76 -177,131 -49,55 -88,119 -116,194 -29,74 -43,152 -43,236 0,88 14,171 42,246 27,76 66,141 117,196 51,55 112,98 184,129 72,31 153,47 243,47 84,0 164,-15 239,-44 74,-29 137,-64 188,-105l0 -369 -450 0 0 -148 610 0 0 588c-69,62 -154,115 -254,159z"/>
    <path class="blanco0" d="M21020 2125c-28,-75 -69,-140 -121,-195 -52,-55 -114,-98 -185,-131 -71,-32 -149,-48 -234,-48 -84,0 -162,16 -233,47 -71,32 -132,75 -183,130 -51,55 -90,120 -119,194 -29,74 -44,153 -44,238 0,84 15,164 44,238 29,75 69,140 121,195 52,55 114,98 185,131 71,32 149,48 234,48 84,0 162,-15 233,-47 71,-32 132,-75 183,-130 51,-55 91,-120 119,-194 29,-73 44,-153 44,-237 0,-85 -15,-164 -44,-239zm162 527c-37,92 -88,174 -154,244 -66,71 -146,127 -239,168 -93,42 -196,63 -309,63 -112,0 -215,-21 -307,-62 -92,-40 -172,-96 -238,-166 -66,-69 -117,-150 -153,-243 -36,-92 -54,-189 -54,-292 0,-103 18,-201 55,-293 37,-92 88,-174 154,-244 66,-71 146,-127 239,-168 93,-42 196,-62 309,-62 112,0 215,20 307,61 92,41 172,96 238,166 66,69 117,151 153,243 36,92 54,190 54,293 0,102 -18,200 -55,292z"/>
    <path class="blanco0" d="M22518 2883c65,-46 97,-112 97,-197 0,-82 -33,-145 -99,-190 -66,-44 -164,-67 -293,-67l-466 0 0 524 495 0c113,0 201,-23 266,-70zm-80 -670c63,-45 95,-111 95,-199 0,-75 -29,-135 -85,-178 -56,-44 -137,-66 -242,-66l-449 0 0 511 436 0c100,0 181,-22 245,-68zm-21 -565c59,18 110,43 152,74 42,32 74,71 97,117 22,45 33,96 33,153 0,49 -6,92 -20,129 -13,36 -31,69 -53,97 -23,28 -48,52 -75,71 -28,19 -56,35 -86,47 44,13 85,29 124,50 39,20 72,45 100,73 28,28 51,62 68,101 16,40 25,85 25,136 0,63 -13,120 -38,171 -25,50 -62,93 -109,128 -47,34 -103,61 -170,79 -66,18 -139,27 -219,27l-656 0 0 -1479 629 0c73,0 139,9 198,26z"/>
    <polygon class="blanco0" points="23298,3101 23131,3101 23131,1622 23298,1622 "/>
    <polygon class="blanco0" points="24800,1774 23898,1774 23898,2279 24705,2279 24705,2431 23898,2431 23898,2949 24811,2949 24811,3101 23731,3101 23731,1622 24800,1622 "/>
    <path class="blanco0" d="M26051 1854c-66,-52 -159,-78 -280,-78l-457 0 0 602 455 0c55,0 106,-7 153,-21 46,-14 87,-34 121,-60 34,-26 60,-58 79,-97 19,-38 29,-81 29,-129 0,-93 -34,-166 -100,-217zm115 1247l-430 -575 -422 0 0 575 -167 0 0 -1479 636 0c82,0 155,10 221,32 65,21 121,51 168,89 46,39 82,86 108,140 25,54 38,115 38,181 0,62 -10,117 -30,166 -20,48 -47,91 -83,127 -36,36 -79,66 -128,90 -50,24 -103,40 -161,50l455 604 -205 0z"/>
    <polygon class="blanco0" points="27763,1622 27926,1622 27926,3101 27793,3101 26838,1888 26838,3101 26675,3101 26675,1622 26831,1622 27763,2808 "/>
    <path class="blanco0" d="M29571 2125c-29,-75 -69,-140 -121,-195 -52,-55 -114,-98 -185,-131 -71,-32 -149,-48 -234,-48 -84,0 -162,16 -233,47 -71,32 -132,75 -183,130 -51,55 -91,120 -119,194 -29,74 -44,153 -44,238 0,84 15,164 44,238 28,75 69,140 121,195 52,55 114,98 185,131 71,32 149,48 234,48 84,0 162,-15 233,-47 71,-32 132,-75 183,-130 51,-55 90,-120 119,-194 29,-73 44,-153 44,-237 0,-85 -15,-164 -44,-239zm162 527c-37,92 -88,174 -154,244 -67,71 -146,127 -239,168 -93,42 -196,63 -309,63 -112,0 -215,-21 -307,-62 -92,-40 -172,-96 -238,-166 -66,-69 -117,-150 -153,-243 -36,-92 -54,-189 -54,-292 0,-103 18,-201 55,-293 37,-92 88,-174 154,-244 66,-71 146,-127 239,-168 93,-42 196,-62 309,-62 112,0 215,20 307,61 92,41 172,96 238,166 66,69 117,151 153,243 36,92 54,190 54,293 0,102 -18,200 -55,292z"/>
    <path class="blanco0" d="M31858 2133c-29,-71 -70,-134 -123,-187 -52,-53 -117,-94 -193,-124 -76,-31 -161,-46 -254,-46l-346 0 0 1171 346 0c93,0 178,-15 254,-44 76,-30 141,-71 193,-124 53,-53 94,-114 123,-185 29,-70 43,-147 43,-230 0,-82 -14,-159 -43,-231zm160 519c-38,91 -92,170 -162,236 -70,66 -153,118 -249,156 -97,38 -203,57 -319,57l-513 0 0 -1479 513 0c116,0 222,19 319,56 96,37 179,89 249,155 70,66 124,145 162,235 38,90 57,187 57,292 0,104 -19,201 -57,292z"/>
    <polygon class="blanco0" points="33497,1774 32595,1774 32595,2279 33402,2279 33402,2431 32595,2431 32595,2949 33508,2949 33508,3101 32428,3101 32428,1622 33497,1622 "/>
    <polygon class="blanco0" points="18071,4071 20628,4071 20628,4821 18943,4821 18943,5302 20469,5302 20469,5997 18943,5997 18943,6502 20651,6502 20651,7251 18071,7251 "/>
    <polygon class="blanco0" points="21101,4071 21923,4071 23231,5752 23231,4071 24103,4071 24103,7251 23331,7251 21973,5507 21973,7251 21101,7251 "/>
    <polygon class="blanco0" points="25443,4844 24490,4844 24490,4071 27279,4071 27279,4844 26325,4844 26325,7251 25443,7251 "/>
    <path class="blanco0" d="M29127 5598c300,0 473,-146 473,-377l0 -9c0,-250 -182,-377 -477,-377l-577 0 0 763 581 0zm-1462 -1527l1503 0c486,0 822,128 1036,346 186,181 281,427 281,740l0 9c0,486 -258,809 -654,977l759 1108 -1018 0 -640 -963 -9 0 -377 0 0 963 -881 0 0 -3180z"/>
    <polygon class="blanco0" points="30931,4071 33488,4071 33488,4821 31803,4821 31803,5302 33329,5302 33329,5997 31803,5997 31803,6502 33511,6502 33511,7251 30931,7251 "/>
    <path class="blanco0" d="M36681 5598c300,0 473,-146 473,-377l0 -9c0,-250 -182,-377 -477,-377l-577 0 0 763 581 0zm-1462 -1527l1503 0c486,0 822,128 1036,346 186,181 281,427 281,740l0 9c0,486 -258,809 -654,977l759 1108 -1018 0 -640 -963 -9 0 -377 0 0 963 -881 0 0 -3180z"/>
    <path class="blanco0" d="M39071 3018l804 258 -668 582 -609 0 473 -840zm-568 1053l886 0 0 3180 -886 0 0 -3180z"/>
    <path class="blanco0" d="M42387 5670l0 -9c0,-459 -332,-858 -818,-858 -482,0 -804,390 -804,849l0 9c0,459 332,859 813,859 486,0 809,-391 809,-850zm-2526 0l0 -9c0,-913 736,-1653 1717,-1653 981,0 1708,731 1708,1644l0 9c0,913 -736,1654 -1717,1654 -981,0 -1708,-732 -1708,-1645z"/>
    <path class="blanco0" d="M43481 6779l491 -586c313,250 663,381 1031,381 236,0 363,-82 363,-218l0 -9c0,-132 -104,-204 -536,-304 -676,-155 -1199,-345 -1199,-1000l0 -9c0,-590 468,-1017 1231,-1017 541,0 963,145 1308,422l-440 623c-291,-205 -609,-314 -890,-314 -214,0 -318,91 -318,205l0 9c0,145 109,209 549,309 732,159 1186,395 1186,990l0 9c0,649 -514,1036 -1286,1036 -563,0 -1099,-178 -1490,-527z"/>
    <polygon class="blanco0" points="46199,109 17985,109 17985,0 46199,0 "/>
    <polygon class="blanco0" points="46199,8922 17985,8922 17985,8813 46199,8813 "/>
    <path class="blanco0" d="M13398 3225c0,650 -485,1058 -1326,1058l-1631 0 0 -2142 1618 0c829,0 1339,357 1339,1058l0 26zm650 2587c1109,-471 1835,-1376 1835,-2740l0 -26c0,-879 -267,-1567 -790,-2077 -599,-612 -1542,-969 -2906,-969l-115 0 -4104 0 -7968 0 0 8922 7240 0 0 -2103 -4793 0 0 -1415 4283 0 0 -1950 -4283 0 0 -1313 5521 0 0 6781 2473 0 0 -2702 1058 0 25 0 1797 2702 2856 0 -2129 -3110z"/>
    <path class="blanco0" d="M2585 0l0 2141 5383 0 0 6781 2473 0 0 -2702 1058 0 25 0 1797 2702 1330 0 0 -2228 -603 -882c216,-92 418,-201 603,-326l0 -4880c-588,-388 -1398,-606 -2464,-606l0 0 -115 0 -4104 0 -5383 0zm0 3454l0 1950 4145 0 0 -1950 -4145 0zm0 3365l0 2103 4655 0 0 -2103 -4655 0zm7856 -4678l1618 0c829,0 1339,357 1339,1058l0 0 0 26c0,650 -485,1058 -1326,1058l0 0 -1631 0 0 -2142z"/>
    <path class="blanco0" d="M7968 8676c712,-962 1741,-2507 2586,-4393l1518 0 0 0c841,0 1326,-408 1326,-1058l0 -26 0 0c0,-701 -510,-1058 -1339,-1058l-609 0c384,-951 620,-1593 784,-2141 1338,8 2267,364 2859,969l0 0c523,510 790,1198 790,2077l0 26 0 0c0,1364 -726,2269 -1835,2740l2129 3110 -2856 0 -1797 -2702 -25 0 -1058 0 0 2702 -2473 0 0 -246z"/>
    <path class="blanco0" d="M6029 8922c-130,-631 -275,-1348 -425,-2103l-3157 0 0 -1415 2879 0c-127,-651 -252,-1310 -370,-1950l-2509 0 0 -1313 2274 0c-139,-800 -258,-1535 -341,-2141l-4380 0 0 8922 6029 0z"/>
    <path class="blanco0" d="M4436 8922c-235,-579 -534,-1307 -871,-2103l-1118 0 0 -1415 510 0c-902,-2060 -1934,-4249 -2709,-5404l-248 0 0 8922 4436 0z"/>
    </g>
  </g>
`,
]
