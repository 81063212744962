import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PlantillaForm from './layout/PlantillaForm'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const PaginaLayout = React.lazy(() => import('./layout/PaginaLayout'))
const NoticiaLayout = React.lazy(() => import('./layout/NoticiaLayout'))
const SitioLayout = React.lazy(() => import('./layout/SitioLayout'))
const SitioHomeLayout = React.lazy(() => import('./layout/SitioHomeLayout'))
const SubmenuLayout = React.lazy(() => import('./layout/SubmenuLayout'))
const SubmenuSitiosLayout = React.lazy(() => import('./layout/SubmenuSitiosLayout'))
const PaginaSitiosLayout = React.lazy(() => import('./layout/PaginaSitiosLayout'))
const FormularioLayout = React.lazy(() => import('./layout/FormularioLayout'))
const FormularioFrame = React.lazy(() => import('./layout/FormularioFrame'))
const ConsultasLayout = React.lazy(() => import('./layout/ConsultasLayout'))

// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const basePath = String(process.env.PUBLIC_URL).trim()

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path={basePath + '/'}
              name="Inicio"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path={[basePath + '/formularios/frame/:form', basePath + '/formulario/frame/:form']}
              name="Inicio"
              render={(props) => <FormularioFrame {...props} />}
            />
            <Route
              exact
              path={[
                basePath + '/formularios/:id/',
                basePath + '/formulario/:id/',
                basePath + '/formularios/:id/:codeMsg',
                basePath + '/formulario/:id/:codeMsg',
              ]}
              name="Inicio"
              render={(props) => <FormularioLayout {...props} />}
            />
            {process.env.NODE_ENV === 'development' && (
              <Route
                exact
                path={[basePath + '/contenedorsecreto']}
                name="Prueba"
                render={(props) => (
                  <PlantillaForm
                    header={{
                      organismo: 'El organismo de Silvia',
                      titulo: 'Este es el título',
                      descripcion: 'Y aca iria una descripción',
                    }}
                    footer={{
                      organismo: 'Dirección General de Silvia',
                      telefono: '343 34543543',
                      email: null,
                      direccion: 'Alameda 542',
                      web: null,
                    }}
                  >
                    Contenido
                  </PlantillaForm>
                )}
              />
            )}
            <Route
              exact
              path={basePath + '*/error404'}
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path={basePath + '/error500'}
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path={basePath + '/inicio'}
              name="Inicio"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route path={basePath + '/ps'} render={(props) => <SubmenuLayout {...props} />} />
            <Route path={basePath + '/pf'} render={(props) => <PaginaLayout {...props} />} />
            <Route
              path={basePath + '/noticias/temas/*'}
              render={(props) => <NoticiaLayout {...props} frame={false} />}
            />
            <Route
              path={basePath + '/noticias/:id'}
              render={(props) => <NoticiaLayout {...props} frame={true} />}
            />
            <Route
              path={basePath + '/noticias'}
              render={(props) => <NoticiaLayout {...props} frame={false} />}
            />
            <Route
              path={basePath + '/consultas/:id'}
              render={(props) => <ConsultasLayout {...props} />}
            />
            <Route
              path={basePath + '/:sitio*/noticias'}
              render={(props) => <SitioLayout {...props} />}
            />
            <Route
              path={basePath + '/:sitio*/noticias/:id'}
              render={(props) => <SitioLayout {...props} />}
            />
            <Route
              path={basePath + '/:sitio*/ps/:id'}
              render={(props) => <SubmenuSitiosLayout {...props} />}
            />
            <Route
              path={basePath + '/:sitio*/pf/:id'}
              render={(props) => <PaginaSitiosLayout {...props} />}
            />
            <Route
              path={basePath + '/:sitio*/inicio'}
              render={(props) => <SitioHomeLayout {...props} />}
            />
            <Route
              path={basePath + '/:sitio*'}
              render={(props) => <SitioHomeLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App
